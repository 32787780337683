
import { Component, PropSync, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import VueQr from 'vue-qr';
import { getStoreTongueQrCode } from "@/api/store";

@Component({
  components: {
    VueQr
  }
})
export default class StoreTongueQrCode extends Vue {
  @PropSync("visible", { type: Boolean }) dialogVisible!: boolean;
  @Prop({ required: true }) storeId!: number;
  @Prop({ required: true }) storeName!: number;
  @Ref("qrCodeRef") qrCodeRef!: VueQr;

  qrCodeUrl = '';

  @Watch('dialogVisible')
  onDialogVisibleChange(val: boolean) {
    if (val) {
      this.fetchQrCode();
    }
  }

  fetchQrCode() {
    getStoreTongueQrCode({ id: this.storeId }).then((res) => {
      this.qrCodeUrl = res.data;
    }).catch(() => {
      this.$message.error('获取二维码失败，请稍后再试');
      this.dialogVisible = false;
    });
  }

  downloadQrCode() {
    let a = document.createElement('a');
    a.download = this.storeName + '舌诊二维码';
    a.href = this.qrCodeRef.imgUrl;
    a.click();
  }
}
